import React, { Component } from 'react';

class App extends Component {
  state = {
    checked: false
  };

  setChecked = val => {
    this.setState({ checked: val });
  };

  render() {
    return (
      <React.Fragment>
        <div className="navigation">
          <input
            type="checkbox"
            className="navigation__checkbox"
            id="navi-toggle"
            checked={this.state.checked}
          />
          <label
            htmlFor="navi-toggle"
            onClick={() => this.setChecked(!this.state.checked)}
            className="navigation__button"
          >
            <span className="navigation__icon">&nbsp;</span>
          </label>

          <div className="navigation__background">&nbsp;</div>
          <nav className="navigation__nav">
            <ul className="navigation__list">
              <li className="navigation__item">
                <a
                  href="#about"
                  onClick={() => this.setChecked(false)}
                  className="navigation__link"
                >
                  About us
                </a>
              </li>
              <li className="navigation__item">
                <a
                  href="#features"
                  onClick={() => this.setChecked(false)}
                  className="navigation__link"
                >
                  Features
                </a>
              </li>
              <li className="navigation__item">
                <a
                  href="#prices"
                  onClick={() => this.setChecked(false)}
                  className="navigation__link"
                >
                  Prices
                </a>
              </li>
              <li className="navigation__item">
                <a
                  href="#reviews"
                  onClick={() => this.setChecked(false)}
                  className="navigation__link"
                >
                  Reviews
                </a>
              </li>
              <li className="navigation__item">
                <a
                  href="#portfolio"
                  onClick={() => this.setChecked(false)}
                  className="navigation__link"
                >
                  Portfolio
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <header className="header">
          {/* <img src="/img/logo-2.png" /> */}
          <div className="container">
            <p>Vanilla Soft</p>
            <p>If you're siteless, just buy a site!</p>
            {/* <p>Avem logo de cacat</p> */}
          </div>
        </header>
        <main className="main">
          <div className="about" id="about">
            <h1>Who are we?</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Accusantium aliquam voluptas, vero minima ducimus quibusdam
              accusamus ratione reprehenderit velit repellendus voluptatibus
              impedit quas laborum tenetur? Odio facere ut consectetur rerum
              sequi quos sed, temporibus ex, id tempore ipsam nesciunt iste
              nobis suscipit illo saepe sunt inventore voluptate minima?
              Laudantium expedita ipsam quod. Animi illo est molestias
              repellendus soluta sint ab temporibus, reprehenderit a tempore
              perspiciatis ipsam, deleniti, veritatis harum et sapiente non quae
              dolore voluptates necessitatibus laborum. Aspernatur numquam
              dolorum maxime iure quam quo vero?
            </p>
          </div>
          <div className="feature" id="features">
            <div className="row">
              <div className="col-md-3">
                <div className="feature-box">
                  <i className="feature-icon fas fa-mobile-alt" />
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Responsive
                  </h3>
                  <p className="feature-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature-box">
                  <i className="feature-icon fas fa-money-bill" />
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Cheap
                  </h3>
                  <p className="feature-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature-box">
                  <i className="feature-icon fas fa-mobile-alt" />
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Responsive
                  </h3>
                  <p className="feature-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature-box">
                  <i className="feature-icon fas fa-mobile-alt" />
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Responsive
                  </h3>
                  <p className="feature-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="price" id="prices">
            <div className="price-title">
              <h2>Our services</h2>
            </div>
            <div className="row">
              <div className="col-md-offset-3 col-md-3 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="card__side card__side--front">
                    <div className="card__picture card__picture--1">&nbsp;</div>
                    <h4 className="card__heading">
                      <span className="card__heading-span card__heading-span--1">
                        Websites
                      </span>
                    </h4>
                    <div className="card__details">
                      <ul>
                        <li>Nicely designed</li>
                        <li>Fast delivered</li>
                        <li>Cheap prices</li>
                      </ul>
                    </div>
                  </div>
                  <div className="card__side card__side--back card__side--back-1">
                    <div className="card__cta">
                      <div className="card__price-box">
                        <p className="card__price-only">Starting from</p>
                        <p className="card__price-value">$500</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="card__side card__side--front">
                    <div className="card__picture card__picture--2">&nbsp;</div>
                    <h4 className="card__heading">
                      <span className="card__heading-span card__heading-span--1">
                        Hosting
                      </span>
                    </h4>
                    <div className="card__details">
                      <ul>
                        <li>Sevice 24/7</li>
                        <li>Fast servers</li>
                        <li>Uptime guaranteed</li>
                      </ul>
                    </div>
                  </div>
                  <div className="card__side card__side--back card__side--back-1">
                    <div className="card__cta">
                      <div className="card__price-box">
                        <p className="card__price-only">Starting from</p>
                        <p className="card__price-value">$500</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reviews" id="reviews">
            <h2>What our customers say?</h2>
            <div className="row">
              <div className="story">
                <figure className="story__shape">
                  <img
                    src="/img/face-1.jpeg"
                    alt="Customer"
                    className="story__img"
                  />
                  <figcaption className="story__caption">John Doe</figcaption>
                </figure>
                <div className="story__text">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    The best services money can buy
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Excepturi impedit animi tempore id quidem repellat. Aliquid
                    perspiciatis esse molestias totam voluptatum quis sequi a
                    perferendis facere, dolorem vel nulla tempora? Excepturi
                    impedit animi tempore id quidem repellat. Aliquid
                    perspiciatis esse molestias totam voluptatum quis sequi a
                    perferendis facere, dolorem vel nulla tempora?
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="story">
                <figure className="story__shape">
                  <img
                    src="/img/face-1.jpeg"
                    alt="Customer"
                    className="story__img"
                  />
                  <figcaption className="story__caption">
                    CASTRAVET RADU
                  </figcaption>
                </figure>
                <div className="story__text">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    S-o primit!
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Excepturi impedit animi tempore id quidem repellat. Aliquid
                    perspiciatis esse molestias totam voluptatum quis sequi a
                    perferendis facere, dolorem vel nulla tempora? Excepturi
                    impedit animi tempore id quidem repellat. Aliquid
                    perspiciatis esse molestias totam voluptatum quis sequi a
                    perferendis facere, dolorem vel nulla tempora?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio" id="portfolio">
            <h2>What have we built so far?</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-1.jpg" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-2.jpg" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-3.jpg" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-4.png" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-5.png" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="pfc">
                  <img src="/img/site-6.jpg" />
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="footer">
          <img src="/img/logo-2.png" />
          <div className="row">
            <div className="col-md-6">
              <a href="facebook.com">
                <li className="fab fa-facebook-f" />
              </a>
              <a href="yahoo.com">
                <li className="fas fa-envelope" />
              </a>
            </div>
            <div className="col-md-6">
              Copyrigth {'\u00A9'} {new Date().getFullYear()} VanillaSoft.
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default App;
